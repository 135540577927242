import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName( 'splide' );
for ( var i = 0; i < elms.length; i++ ) {
  new Splide(elms[ i ], {
    height: 164,
    perPage: 8,
    gap: '10px',
    pagination: false,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    breakpoints: {
      1920: {
        perPage: 7,
      },
      1200: {
        perPage: 6,
      },
      992: {
        perPage: 5,
      },
      768: {
        perPage: 4,
      },
      576: {
        perPage: 3,
      }
    }
  }).mount();
}